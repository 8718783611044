


























































































































































import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { extract_skill_group_styling } from "@/utils/skills_group";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import AddNewSkill from "@/components/shared/view_profile/AddNewSkill.vue";
import { get_proficiency_levels_name } from "@/utils/proficiency_levels";

export default Vue.extend({
  name: "SkillsChips",
  methods: { get_proficiency_levels_name, extract_skill_group_styling },
  components: { AddNewSkill, DataNotFound },
  data() {
    return {
      add_new_skills: false,
      show_proficiency_box: false
    };
  },
  props: {
    card_base: {
      type: Boolean,
      default: true
    },
    title: {
      type: String as PropType<TranslateResult>
    },
    skills: {
      type: Array as PropType<Candidates.Skill[]>,
      required: true
    },
    assessment: {
      type: Object as PropType<Candidates.Assessment>
    },
    allow_add: {
      type: Boolean,
      default: false
    },
    active_skill_group: {
      type: String,
      required: true
    },
    hide_title: {
      type: Boolean,
      default: false
    },
    content_extra_style: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    levels_required: {
      type: Boolean,
      default: false
    },
    removeable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  }
});

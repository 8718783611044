























import Vue from "vue";

export default Vue.extend({
  name: "TextWithSeeMore",
  props: {
    text: {
      type: String,
      required: true
    },
    maxLines: {
      type: Number,
      default: 2
    }
  },

  data() {
    return {
      expanded: false,
      show_see_more_btn: false
    };
  },
  watch: {
    text(n) {
      if (n) {
        const text: HTMLParagraphElement = this.$refs
          .text as HTMLParagraphElement;
        this.show_see_more_btn = text.scrollHeight > text.offsetHeight;
      } else this.show_see_more_btn = false;
    }
  },

  mounted() {
    const text: HTMLParagraphElement = this.$refs.text as HTMLParagraphElement;
    if (text.scrollHeight > text.offsetHeight) this.show_see_more_btn = true;
  }
});

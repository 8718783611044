import { Candidates } from "@/interfaces/data_objects/candidates";
import { User } from "@/interfaces/data_objects/user";

export enum ScoreConstants {
  PROFICIENCY = 10,
  SKILLS = 20,
  EDUCATION = 10,
  CERTIFICATION = 10,
  WORK = 10,
  PERSONAL_DETAILS = 10,
  PROFILE_DETAILS = 6,
  AVATAR = 10,
  TOTAL_EXPERIENCE = 5,
  MEDIA = 5,
  SOCIAL_LINKS = 0
}
const calculateProfileScore = (
  profile: Candidates.CandidateDetails,
  user: User
): number => {
  const score_exist = sessionStorage.getItem("profile_score");
  if (score_exist) return parseInt(score_exist);
  let score = 0;
  if (user.social_links) score += user.social_links.length;
  if (user.avatar_uri) score += ScoreConstants.AVATAR;
  if (profile.profile) {
    const _profile = profile.profile;
    score += ScoreConstants.PROFILE_DETAILS;
    if (_profile.assessment) score += ScoreConstants.PROFICIENCY;
    if (
      _profile.education_detailed &&
      Object.values(_profile.education_detailed).length > 0
    )
      score += ScoreConstants.EDUCATION;
    if (
      _profile.work_history_detailed &&
      Object.values(_profile.work_history_detailed).length > 0
    )
      score += ScoreConstants.WORK;
    if (
      _profile.certification_detailed &&
      Object.values(_profile.certification_detailed).length > 0
    )
      score += ScoreConstants.CERTIFICATION;
    if (_profile.personal_details) score += ScoreConstants.PERSONAL_DETAILS;
    if (_profile.media && _profile.media.length > 0)
      score += ScoreConstants.MEDIA;
    if (_profile.total_experience) score += ScoreConstants.TOTAL_EXPERIENCE;
  }
  if (profile.skills && profile.skills.length > 0)
    score += ScoreConstants.SKILLS;

  sessionStorage.setItem("profile_score", score.toString());
  return score;
};

const clearProfileScore = (): void =>
  sessionStorage.removeItem("profile_score");

export default {
  calculateProfileScore,
  clearProfileScore
};

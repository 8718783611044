





























































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_USER_DETAILS,
  USER_DETAILS_BY_ID,
  USER_PROFILE,
  USER_PROFILE_BY_ID
} from "@/store/modules/auth/constants";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { get_name_initials } from "@/utils/global";
import { get_color_by_name, get_random_color_hash } from "@/utils/colors";
import HighlightsAwards from "@/components/shared/view_profile/HighlightsAwards.vue";
import EducationDetails from "@/components/shared/view_profile/EducationDetails.vue";
import CertificationDetails from "@/components/shared/view_profile/CertificationDetails.vue";
import WorkExperience from "@/components/shared/view_profile/WorkExperience.vue";
import Personality from "@/components/shared/view_profile/Personality.vue";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import SkillsChips from "@/components/shared/SkillsChips.vue";
import ProfilePicture from "@/components/shared/view_profile/ProfilePicture.vue";
import UserName from "@/components/shared/view_profile/UserName.vue";
import UserRating from "@/components/shared/view_profile/UserRating.vue";
import UserDesignation from "@/components/shared/view_profile/UserDesignation.vue";
import {
  CANDIDATE_VIEW_PAYLOAD,
  INTERVIEW_LISTING,
  POKE_CANDIDATE,
  POKE_CANDIDATE_LOADING
} from "@/store/modules/recruiter/constants";
import { GET_JOB_MATCH_AGAINST_JOB_ID } from "@/store/modules/candidates/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import SkillMatch from "@/components/shared/view_profile/SkillMatch.vue";

import UserExperience from "@/components/shared/view_profile/UserExperience.vue";
import {
  InterviewListingPayload,
  PokeCandidatePayload
} from "@/store/modules/recruiter/interfaces";
import { get_interview_status_msg } from "@/utils/interviews";
import { SkillGap as SG } from "@/interfaces/responses/skill_gap";
import SkillGap from "@/components/shared/view_profile/SkillGap.vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import UserProfileLoader from "@/components/shared/UserProfileLoader.vue";

export default Vue.extend({
  name: "ViewCandidateProfile",
  components: {
    SkillGap,
    UserExperience,
    SkillMatch,
    UserDesignation,
    UserRating,
    UserName,
    ProfilePicture,
    SkillsChips,
    SkillsGroup,
    Personality,
    WorkExperience,
    CertificationDetails,
    EducationDetails,
    HighlightsAwards,
    GoBackHeader,
    UserProfileLoader
  },
  data() {
    return {
      loading: true,
      job_details_loading: true,
      active_skill_group: "",
      designation: null,
      job_details: null as null | SG.Root,
      data: null as any,
      interview_status_loading: false,
      interview_status_msg: "",
      interview_exist: false,
      interview_status: "" as string,
      social_icons: {
        linkedin: require("@/assets/logos/linkedin-3.svg"),
        twitter: require("@/assets/logos/twitter.svg"),
        instagram: require("@/assets/logos/instagram.svg"),
        facebook: require("@/assets/logos/facebook.svg")
      }
    };
  },
  watch: {
    get_site_direction() {
      this.set_interview_message();
    }
  },
  computed: {
    ...mapGetters("recruiter", {
      get_candidate_view_payload: CANDIDATE_VIEW_PAYLOAD,
      get_poke_candidate_loading: POKE_CANDIDATE_LOADING
    }),
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS,
      user_profile: USER_PROFILE
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME,
      get_site_direction: SITE_DIRECTION
    })
  },
  // Validating route param type is int on router enter
  beforeRouteEnter(to, from, next) {
    const user = sessionStorage.getItem("user");
    const upload = sessionStorage.getItem("upload");
    if (!user || !upload) next(false);
    else next();
  },

  async created() {
    await this.init_data();
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("candidate", {
      fetch_skill_gap: GET_JOB_MATCH_AGAINST_JOB_ID
    }),
    ...mapActions("recruiter", {
      poke_candidate: POKE_CANDIDATE,
      fetch_interview: INTERVIEW_LISTING
    }),
    get_color_by_name,
    get_random_color_hash,
    get_name_initials,
    ...mapActions("auth", {
      fetch_profile_by_id: USER_PROFILE_BY_ID,
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    async init_data() {
      this.loading = true;
      this.job_details_loading = true;
      this.interview_status_loading = true;
      const cv_id = parseInt(sessionStorage.getItem("user") ?? "");
      const job_id = parseInt(sessionStorage.getItem("job") ?? "");
      const upload_response_id = sessionStorage.getItem("upload" ?? "");
      if (isNaN(cv_id) || !upload_response_id) {
        this.root_error("Invalid Job");
        await this.$router.push("/recruiter");
        return;
      }
      // Getting user profile fetched from cv
      const user_profile = await this.fetch_profile_by_id(cv_id);
      // Getting user details
      const user_details = await this.fetch_user_by_id(cv_id);
      this.data = {
        user: user_details,
        profile: user_profile
      };
      this.loading = false;
      // Fetching job match & job gap
      if (job_id) {
        const skill_gap = await this.fetch_skill_gap({
          job_id: job_id,
          cv_id: upload_response_id
        });
        if (skill_gap) this.job_details = skill_gap;
        else {
          this.root_error("Failed to get job match & job match");
        }
        await this.fetch_interview_details(job_id, user_details.id);
      }
      this.job_details_loading = false;
      this.interview_status_loading = false;
    },
    set_interview_message() {
      if (!this.interview_status) {
        this.interview_status_msg = this.$t(
          "shared.interview.invite"
        ).toString();
        return;
      }
      this.interview_status_msg =
        this.$t("shared.interview.interview") +
        get_interview_status_msg(
          this.interview_status,
          this.user_details.role_id
        );
    },
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },
    transform_poke_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("stroke", get_color_by_name("white"));
      return icon;
    },
    transform_resume_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) {
        node.setAttribute("fill", get_color_by_name("white"));
        node.setAttribute("stroke", get_color_by_name("grey"));
      }
      return icon;
    },
    /**
     * FUnction to send API request to poke candidate
     */
    async _poke_candidate() {
      const cv_id = parseInt(sessionStorage.getItem("user") ?? "");
      const job_id = parseInt(sessionStorage.getItem("job") ?? "");
      // Validating candidate_id and job_id
      if (isNaN(cv_id) || isNaN(job_id)) {
        this.root_error(this.$t("errors.job-not-exist"));
        return;
      }
      const payload: PokeCandidatePayload = {
        candidate_id: cv_id,
        job_id: job_id,
        type: "interview_invite"
      };
      await this.poke_candidate(payload);
      await this.fetch_interview_details(job_id, cv_id);
    },
    async fetch_interview_details(job_id: number, uid: number) {
      this.interview_status_loading = true;
      const interview_payload: InterviewListingPayload = {
        job_id: job_id,
        candidate_id: uid,
        interviewed_by: this.user_details.id
      };
      const result = await this.fetch_interview(interview_payload);
      if (result.length > 0) {
        this.interview_status = result[0].interview_status;
        this.interview_exist = true;
        this.interview_status_msg =
          this.$t("shared.interview.interview") +
          get_interview_status_msg(
            result[0].interview_status,
            this.user_details.role_id
          );
      } else
        this.interview_status_msg = this.$t(
          "shared.interview.invite"
        ).toString();
      this.interview_status_loading = false;
    }
  }
});








import Vue, { PropType } from "vue";
import { User } from "@/interfaces/data_objects/user";
import { mapGetters } from "vuex";
import { GET_USER_NAME } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "UserName",
  data() {
    return {
      name: ""
    };
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    own: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // If last name not exist => assign empty string
    const last_name = this.user.last_name ? this.user.last_name : "";
    this.name = this.user.first_name + " " + last_name;
  },
  computed: {
    ...mapGetters("auth", { get_user_name: GET_USER_NAME })
  },
  watch: {
    get_user_name(n) {
      if (n && this.own) {
        this.name = n;
      }
    }
  }
});

export enum Proficiency {
  PROFICIENT = "Proficient",
  INTERMEDIATE = "Intermediate",
  NOVICE = "Novice",
  EXPERT = "Expert"
}

export enum ProficiencyLevels {
  PROFICIENT = "L3",
  INTERMEDIATE = "L2",
  NOVICE = "L1",
  EXPERT = "L4"
}

export function get_proficiency_levels_name(
  level: Proficiency
): ProficiencyLevels {
  if (level === Proficiency.EXPERT) return ProficiencyLevels.EXPERT;
  else if (level === Proficiency.INTERMEDIATE)
    return ProficiencyLevels.INTERMEDIATE;
  else if (level === Proficiency.NOVICE) return ProficiencyLevels.NOVICE;
  else return ProficiencyLevels.PROFICIENT;
}
